import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Theme/layout';
import cs from 'classnames';
import SEO from '../components/seo';
import styles from './czytam.module.scss';
import ArticleItem from '../components/ArticleItem/ArticleItem';
import BigArticleTitle from '../components/shared/texts/BigArticleTitle/BigArticleTitle';

const IndexPage = ({ data }) => {
  const { allDatoCmsArticle: { nodes: articles } } = data;
  return (
    <Layout>
      <SEO title="Czytam"/>
      <div className={cs(styles.blogWrap, 'firstSectionWrap')}>
        <BigArticleTitle className={styles.title}>Czytam</BigArticleTitle>
        {articles.map((article, index) => {
            if (index % 2 === 0) {
              return <ArticleItem id={article.id} img={article.miniature.fluid} title={article.title}
                                  shortDescription={article.shortDescription}/>;
            } else {
              return <ArticleItem id={article.id} img={article.miniature.fluid} title={article.title}
                                  shortDescription={article.shortDescription} reverse/>;
            }
          }
        )}
      </div>
    </Layout>
  );
};
export const query = graphql`
    {
        allDatoCmsArticle {
            nodes{
                title
                promotedOnFrontPage
                shortDescription
                id
                miniature {
                    alt
                    fluid(maxWidth: 900) {
                        ...GatsbyDatoCmsFluid_tracedSVG
                    }
                }
            }
        }
    }
`;

export default IndexPage;
